import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import Icon from '../../../../common/components/icon';
import { Button } from '../../../../common/components/button';

class ExchangeActions extends Component {
  static propTypes = {
    onAccept: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { isAccepting: false, isDeclining: false };
  }

  handleAccept() {
    this.setState({ isAccepting: true });

    return this.props.onAccept();
  }

  handleDecline() {
    this.setState({ isDeclining: true });

    return this.props.onDecline();
  }

  render() {
    const { isAccepting, isDeclining } = this.state;

    return (
      <div className="Exchange__Actions">
        <Button
          type="default"
          size="large"
          center
          onClick={() => this.handleAccept()}
          isLoading={isAccepting}
        >
          <Icon type="check" className="Exchange__Actions--accept" />
          <Trans i18nKey="flexchange:exchange_actions_accept" />
        </Button>

        <Button
          type="default"
          size="large"
          center
          onClick={() => this.handleDecline()}
          isLoading={isDeclining}
        >
          <Icon type="close" className="Exchange__Actions--decline" />
          <Trans i18nKey="flexchange:exchange_actions_decline" />
        </Button>
      </div>
    );
  }
}

export default ExchangeActions;
