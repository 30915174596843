import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import Modal from '@common/components/modal';
import Permission from '@common/components/permission';
import Spinner from '@common/components/spinner';
import ActivityFeed from '../activity-feed';
import Info from './info';
import ExchangeStatus from './status';
import OwnerActions from './owner-actions';
import PostComment from './post-comment';

import fetchExchange from '@modules/flexchange/actions/fetch-exchange';
import deleteExchange from '@modules/flexchange/actions/delete-exchange';
import onReject from '@modules/flexchange/actions/reject-exchange';
import onDecline from '@modules/flexchange/actions/decline-exchange';
import onAccept from '@modules/flexchange/actions/accept-exchange';
import onApprove from '@modules/flexchange/actions/approve-exchange';
import onPostComment from '@modules/flexchange/actions/post-exchange-comment';
import onSeen from '../../../core/actions/clear-notification';

import { useAppSelector } from '@common/hooks';
import { ESourceTypes, EPermissions } from '@common/definitions';
import { EExchangeStatus } from '../../utils/exchange';
import * as exchangeSelector from '../../selectors/exchange';
import * as networkSelector from '../../../network/selectors/network';
import { LoggedUser, Organisation } from '@common/types/objects';

type ExchangeDetailComponentOwnProps = {
  exchangeId: string;
  organisation: Organisation;
  loggedUser: LoggedUser;
  permissions?: Permissions;
};

const ExchangeDetailComponent = ({
  exchangeId, loggedUser, organisation, permissions,
}: ExchangeDetailComponentOwnProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const network = useAppSelector(networkSelector.selected);
  const exchange = useAppSelector((state) => exchangeSelector.item()(state, exchangeId));

  useEffect(() => {
    const load = async () => {
      await dispatch(fetchExchange(exchangeId));
      dispatch(onSeen(ESourceTypes.EXCHANGE, exchangeId));
    };
    load();
  }, [dispatch, exchangeId, onSeen, fetchExchange]);

  const onClose = () => {
    history.push(`/networks/${network.id}/flexchange`);
  };

  const onDelete = async () => {
    await dispatch(deleteExchange(exchange.id));
    onClose();
  };

  return (
    <Modal
      list
      size="small"
      className="Exchange"
      onExited={onClose}
      content={!exchange ? (
        <Spinner centered />
      ) : (
        <div>
          <div className="Exchange__item">
            {exchange.status !== EExchangeStatus.EXPIRED && exchange.status !== EExchangeStatus.APPROVED && (
              <div className="Exchange__OwnerActions pull-right">
                <Permission
                  test={(exchange.user && exchange.user.id === loggedUser.id)}
                  name={[EPermissions.NETWORK_EXCHANGES_REVIEW, EPermissions.NETWORK_FUNCTION_MEMBERSHIPS_EXCHANGES_REVIEW]}
                >
                  <OwnerActions
                    exchange={exchange}
                    onDelete={onDelete}
                  />
                </Permission>
              </div>
            )}

            <Info exchange={exchange} />

            <ExchangeStatus
              exchange={exchange}
              permissions={permissions}
              organisation={organisation}
              loggedUser={loggedUser}
              onAccept={() => dispatch(onAccept(exchangeId))}
              onDecline={() => dispatch(onDecline(exchangeId))}
              onApprove={(userId: string) => dispatch(onApprove(exchangeId, userId))}
              onReject={(userId: string) => dispatch(onReject(exchangeId, userId))}
            />
          </div>

          <div className="Exchange__feed">
            <PostComment
              loggedUser={loggedUser}
              onPostComment={(text: string) => dispatch(onPostComment(exchangeId, text))}
            />
            <ActivityFeed
              loggedUser={loggedUser}
              activities={exchange.activities}
            />
          </div>
        </div>
      )}
    />
  );
};

export default ExchangeDetailComponent;
