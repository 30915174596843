import * as React from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import PureList from '../../../../common/components/list';
import { Button } from '../../../../common/components/button';
import ExchangeItem from '../exchange-item';
import { FlexchangeTypes } from '../../utils/integration';

export const isToday = (date) => date.isSame(moment(), 'day');
export const isBeforeToday = (date) => date.startOf('day').isBefore(moment().startOf('day'), 'day');
const createClassName = (date) => (isToday(date) ? 'CalendarDay CalendarDay--today' : 'CalendarDay');

const CalendarDay = ({ organisation, day, addExchangeForDate, ...exchangeProps }) => {
  const className = createClassName(day.date);

  return (
    <div className={className}>
      <div className="CalendarDay__header">
        {day.date.format('dddd D')}
      </div>

      <div className="CalendarDay__exchanges">
        <PureList
          items={day.exchanges}
          renderRow={ExchangeItem}
          rowProps={exchangeProps}
          placeholder={(
            <small className="visible-md">
              <Trans i18nKey="flexchange:calendar_day_placeholder" />
            </small>
          )}
        />

        {(!organisation.integration || organisation.integration.flexchange_type === FlexchangeTypes.DEFAULT) && day.date.isAfter(moment().subtract(1, 'day')) && (
          <Button
            iconRight="add"
            type="inverted-primary"
            className="CalendarDay__exchanges__add"
            onClick={() => addExchangeForDate(day.date)}
          >
            <span className="visible-ld"><Trans i18nKey="flexchange:calendar_day_create" /></span>
            <span className="hidden-ld"><Trans i18nKey="flexchange:calendar_day_new_shift" /></span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default CalendarDay;
