import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import ProfileImage from '@common/components/profile-image';
import i18n from '../../../../i18n';

class ExchangeItemBase extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    loggedUser: PropTypes.object.isRequired,
    openExchange: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.handleOpenExchange = this.handleOpenExchange.bind(this);
  }

  getTitle(item) {
    if (item.title) return item.title;

    const startTime = moment(item.start_time).format('HH:mm');
    const endTime = item.end_time ? moment(item.end_time).format('HH:mm') : i18n.t('flexchange:exchange_item_no_end_time');

    return `${startTime} - ${endTime}`;
  }

  getStatus() {
    return {
      icon: null,
      text: '',
      className: null,
    };
  }

  handleOpenExchange() {
    const { item, openExchange } = this.props;

    openExchange(item);
  }

  render() {
    const { item, loggedUser } = this.props;

    const title = this.getTitle(item);
    const { icon, text, className } = this.getStatus();

    return (
      <div
        className={['ExchangeItem', className].filter((c) => !!c).join(' ')}
        onClick={this.handleOpenExchange}
      >
        <div className="ExchangeItem__title">{title}</div>

        <div className="ExchangeItem__status">
          <div className="ExchangeItem__status__indicator">
            <div className="ExchangeItem__status__icon">{icon}</div>
            <small>{text}</small>
          </div>

          <div className="ExchangeItem__user">
            <ProfileImage
              size={30}
              user={item.user}
              enablePopover={item.user && item.user.id !== loggedUser.id}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ExchangeItemBase;
