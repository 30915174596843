import * as React from 'react';
import * as R from 'ramda';
import { Trans } from 'react-i18next';
import PureList from './index';
import { Button } from '../button';

class PaginatedList extends React.Component {
  static defaultProps = {
    ShowMoreComponent: ({ onShowMore }) => (
      <div className="PaginatedList__ShowMore">
        <Button onClick={onShowMore}>
          <Trans i18nKey="common:paginated_list_show_more" />
        </Button>
      </div>
    ),
  };

  constructor(props) {
    super();

    this.state = {
      visible: props.initialListSize
        ? R.min(props.initialListSize, props.items.length)
        : -1,
      total: props.items.length,
    };

    this.handleShowMore = this.handleShowMore.bind(this);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const { visible } = this.state;
    const { items } = this.props;

    if (items !== nextProps.items) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({
        ...this.state,
        visible: visible !== -1
          ? R.min(nextProps.initialListSize, nextProps.items.length)
          : -1,
        total: nextProps.items.length,
      });
    }
  }

  static props;

  handleShowMore() {
    this.setState({ visible: -1 });
  }

  render() {
    let showMoreElement = null;
    const { visible, total } = this.state;
    const {
      order, ShowMoreComponent, header, footer, ...props
    } = this.props;

    let { items } = this.props;
    if (visible > -1) items = order === 'DESC' ? R.takeLast(visible, items) : R.take(visible, items);

    if (visible < total && visible !== -1) {
      showMoreElement = (
        <ShowMoreComponent
          onShowMore={this.handleShowMore}
          count={total - visible}
        />
      );
    }

    return (
      <div className="PaginatedList">
        {header}
        {order === 'DESC' && showMoreElement}
        <PureList
          {...props}
          items={items}
        />
        {order === 'ASC' && showMoreElement}
        {footer}
      </div>
    );
  }
}

export default PaginatedList;
