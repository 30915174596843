import * as R from 'ramda';
import Api from '../../../common/services/api';
import * as exchangeUtil from '../utils/exchange';

export const FLEXCHANGE_REQUEST_EXCHANGE = 'flexchange/REQUEST_EXCHANGE';
export const FLEXCHANGE_RECEIVE_EXCHANGE = 'flexchange/RECEIVE_EXCHANGE';

export default (exchangeId) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  dispatch({ type: FLEXCHANGE_REQUEST_EXCHANGE, exchangeId });

  const [{ data }, { data: activities }, { data: comments }] = await Promise.all([
    Api.get(`/v2/networks/${selected}/exchanges/${exchangeId}`),
    Api.get(`/v3/networks/${selected}/exchanges/${exchangeId}/activity_feed`),
    Api.get(`/v2/networks/${selected}/exchanges/${exchangeId}/comments`),
  ]);

  return dispatch({
    type: FLEXCHANGE_RECEIVE_EXCHANGE,
    exchangeId,
    exchange: exchangeUtil.transfromExchange(data),
    comments,
    activities,
    related: {
      users: R.pipe(
        R.map(R.path(['data', 'user'])),
        R.append(data.user),
        R.reject(R.isNil),
        R.uniqBy(R.prop('id')),
      )(activities),
    },
  });
};
