import permissionsSwitch from '../../../../common/utils/permission-import';
import ExchangeItemEmployee from './index.employee';
import ExchangeItemAdmin from './index.admin';
import { EPermissions } from '../../../../common/definitions';

export default permissionsSwitch(
  [EPermissions.NETWORK_FUNCTION_MEMBERSHIPS_EXCHANGES_REVIEW, EPermissions.NETWORK_EXCHANGES_REVIEW],
  {
    employee: ExchangeItemEmployee,
    admin: ExchangeItemAdmin,
  },
);
