import * as R from 'ramda';
import moment from 'moment';
import * as reducer from '../reducers/exchanges';
import * as daysReducer from '../reducers/days';
import { EPermissions } from '../../../common/definitions';

export const createDay = R.curry((state, filters, date) => ({
  date,
  exchanges: R.pipe(
    ...filters,
    R.map(reducer.findById(state)),
  )(daysReducer.findByDate(state, date)),
}));

const toDate = R.curry((currentWeek, day) => moment(currentWeek).add(day, 'days'));
const filterByStatus = (state, status) => R.intersection(state.flexchange.status[status]);
const filterByFunction = (state, id) => R.intersection(state.flexchange.functions[id] || []);

export default (state) => {
  const { ui, flexchange, network: { selected }, permissions } = state;

  // Clone current week object to set correct locale
  const currentWeek = moment(ui.flexchange.currentWeek.toDate());

  const isAdmin = permissions.networks[selected]
    ? permissions.networks[selected].includes(EPermissions.NETWORK_EXCHANGES_REVIEW)
    : false;
  const { filter, status } = ui.flexchange.search;

  const filters = [R.filter(R.T)];
  if (filter) filters.push(filterByFunction(state, filter));
  if (status) filters.push(filterByStatus(state, status));
  if (status === 'OPEN' && isAdmin) filters.push(R.difference(R.__, flexchange.status.AWAITING_APPROVAL));

  return {
    date: currentWeek,
    days: R.pipe(
      R.map(toDate(currentWeek)),
      R.map(createDay(state, filters)),
    )(R.range(0, 7)),
  };
};
