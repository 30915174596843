import PropTypes from 'prop-types';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Profile from '../../../../common/components/profile';
import ImageItem from '../../../../common/components/image-item/large';
import Permission from '../../../../common/components/permission';
import Icon from '../../../../common/components/icon';
import { EExchangeStatus } from '../../utils/exchange';
import Actions from './actions';

const getExchangeStatus = (exchange, t) => {
  switch (exchange.status) {
    case EExchangeStatus.ACCEPTED:
      return { text: t('flexchange:exchange_status_employee_accepted'), icon: 'check' };
    case EExchangeStatus.DECLINED:
      return { text: t('flexchange:exchange_status_employee_declined'), icon: 'clear' };
    case EExchangeStatus.REJECTED:
      return { text: t('flexchange:exchange_status_employee_rejected'), icon: 'clear' };
    default: return {};
  }
};

const StatusFooter = ({ exchange, onAccept, onDecline }) => {
  switch (exchange.status) {
    case EExchangeStatus.ACCEPTED:
      return (
        <div
          className="Exchange__Status Exchange__Status--state-declined Exchange__Status--revert"
          onClick={onDecline}
        >
          <Icon type="clear" color="red" />
          <Trans i18nKey="flexchange:exchange_status_employee_accepted_revert" components={[<b>a</b>]} />
        </div>
      );
    case EExchangeStatus.DECLINED:
      return (
        <div
          className="Exchange__Status Exchange__Status--state-accepted Exchange__Status--revert"
          onClick={onAccept}
        >
          <Icon type="check" color="green" />
          <Trans i18nKey="flexchange:exchange_status_employee_declined_revert" components={[<b>a</b>]} />
        </div>
      );
    default: return null;
  }
};

StatusFooter.propTypes = {
  exchange: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

const ExchangeStatusEmployee = ({
  exchange, loggedUser, onAccept, onDecline, t,
}) => {
  switch (exchange.status) {
    case EExchangeStatus.ACCEPTED:
    case EExchangeStatus.DECLINED:
    case EExchangeStatus.REJECTED: {
      const status = getExchangeStatus(exchange, t);
      const className = `Exchange__Status Exchange__Status--state-${exchange.status.toLowerCase()}`;

      return (
        <div key={exchange.status}>
          <div className={className}>
            <Profile user={loggedUser}>
              <small>
                <Icon
                  type={status.icon}
                  color={exchange.status === EExchangeStatus.ACCEPTED ? 'green' : 'red'}
                />
                {status.text}
              </small>
            </Profile>
          </div>
          <Permission test={exchange.user.id !== loggedUser.id}>
            <StatusFooter {...{ exchange, onAccept, onDecline }} />
          </Permission>
        </div>
      );
    }
    case EExchangeStatus.EXCHANGED:
    case EExchangeStatus.APPROVED:
      return (
        <div className="Exchange__Status Exchange__Status--state-approved">
          <Profile
            user={exchange.approved_user}
            enablePopover={exchange.approved_user.id !== loggedUser.id}
          >
            <small><Trans i18nKey="flexchange:exchange_status_employee_approved" /></small>
          </Profile>
        </div>
      );
    case EExchangeStatus.EXPIRED:
      return (
        <div className="Exchange__Status Exchange__Status--state-expired">
          <ImageItem
            icon="access_time"
            name={t('flexchange:exchange_status_employee_expired_title')}
          >
            <small><Trans i18nKey="flexchange:exchange_status_employee_expired" /></small>
          </ImageItem>
        </div>
      );
    case EExchangeStatus.OPEN:
      return (
        <Permission test={exchange.user.id !== loggedUser.id}>
          <div className="Exchange__Status Exchange__Status--state-open">
            <Actions
              onAccept={onAccept}
              onDecline={onDecline}
            />
          </div>
        </Permission>
      );
    default: return null;
  }
};

ExchangeStatusEmployee.propTypes = {
  exchange: PropTypes.object.isRequired,
  loggedUser: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ExchangeStatusEmployee);
