import moment from 'moment';
import Api from '../../../common/services/api';
import { ActivityTypes } from '../utils/activity';
import createId from '../../../common/utils/create-temp-id';

export const FLEXCHANGE_ADD_COMMENT = 'flexchange/POST_EXCHANGE_COMMENT';

const createPostCommentActivity = (exchangeId, loggedUser, comment) => ({
  type: 'activity',
  data: {
    id: createId(), // TODO: replace with actual id
    activity_type: ActivityTypes.EXCHANGE_COMMENT,
    date: moment().toISOString(),
    meta_data: { comment_id: comment.id },
    source_id: exchangeId,
    user: loggedUser,
  },
});

export default (exchangeId, text) => async (dispatch, getState) => {
  const { network: { selected }, loggedUser } = getState();

  const payload = { text };
  const { data: comment } = await Api
    .post(`/v2/networks/${selected}/exchanges/${exchangeId}/comments`, payload);

  return dispatch({
    type: FLEXCHANGE_ADD_COMMENT,
    exchangeId,
    comment,
    activity: createPostCommentActivity(exchangeId, loggedUser, comment),
  });
};
