import React from 'react';
import { useTranslation } from 'react-i18next';

import ConfirmButton from '@common/components/confirm-button';
import { Button } from '@common/components/button';

const ExchangeOwnerActions = ({ exchange, onDelete }) => {
  const { t } = useTranslation();
  return (
    <ConfirmButton
      danger
      title={t('flexchange:exchange_owner_actions_confirm_remove_title')}
      onConfirm={() => onDelete(exchange.id)}
      confirmText={t('flexchange:exchange_owner_actions_delete')}
    >
      <Button size="large" icon="delete" />
    </ConfirmButton>
  );
};

export default ExchangeOwnerActions;
