import * as R from 'ramda';
import moment from 'moment';
import Api from '../../../common/services/api';
import * as exchangeUtil from '../utils/exchange';

export const FLEXCHANGE_RECEIVE_EXCHANGES = 'flexchange/RECEIVE_EXCHANGES';

export default (week) => async (dispatch, getState) => {
  const { network: { selected } } = getState();

  const fromDate = week.format('YYYY-MM-DD');
  const endDate = moment(fromDate).add(6, 'days').format('YYYY-MM-DD');

  const { data } = await Api
    .get(`/v2/networks/${selected}/exchanges?start=${fromDate}&end=${endDate}`);

  return dispatch({
    type: FLEXCHANGE_RECEIVE_EXCHANGES,
    items: R.map(exchangeUtil.transfromExchange, data),
    related: {
      users: R.pipe(
        R.map((exchange) => [exchange.user, exchange.approved_user]),
        R.flatten,
        R.reject(R.isNil),
        R.uniqBy(R.prop('id')),
      )(data),
    },
  });
};
