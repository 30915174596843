import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ProfileImage from '@common/components/profile-image';
import mergedNamesText from '../../../../common/utils/merged-names-text';
import { ActivityTypes } from '../../utils/activity';

const getText = (type, values, t) => {
  switch (type) {
    case ActivityTypes.EXCHANGE_CREATED:
      return t('flexchange:activity_feed_text_exchange_created', {
        userFullName: values.user.full_name,
        placedIn: mergedNamesText(values.placedIn, 5, null, (name) => name).join(''),
      });
    case ActivityTypes.EXCHANGE_ACCEPTED:
      return t('flexchange:activity_feed_text_exchange_accepted', {
        userFullName: values.user.full_name,
      });
    case ActivityTypes.EXCHANGE_APPROVED:
      return t('flexchange:activity_feed_text_exchange_approved', {
        approverFullName: values.approver.full_name,
        approvedUserFullName: values.approvedUser.full_name,
      });
    case ActivityTypes.EXCHANGE_REJECTED:
      return t('flexchange:activity_feed_text_exchange_rejected', {
        rejecterFullName: values.rejecter.full_name,
        rejectedUserFullName: values.rejectedUser.full_name,
      });
    default:
      return '';
  }
};

const ActivityFeedActivity = ({ activity, loggedUser }) => {
  const { t } = useTranslation();

  const text = getText(activity.activity_type, activity.values, t);

  return (
    <div className="ActivityFeed__Activity">
      <div className="ActivityFeed__Activity__image">
        <ProfileImage
          user={activity.user}
          enablePopover={!!(activity.user && activity.user.id !== loggedUser.id)}
        />
      </div>

      <div className="ActivityFeed__Activity__content">
        <div>{text}</div>
        <div><small>{moment(activity.date).calendar()}</small></div>
      </div>
    </div>
  );
};

export default ActivityFeedActivity;
