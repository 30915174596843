import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import { Button } from '../../../../common/components/button';
import Profile from '../../../../common/components/profile';
import Permission from '../../../../common/components/permission';
import { EPermissions } from '../../../../common/definitions';

const ReplacementComponent = ({ item, loggedUser, onApprove, onReject }) => {
  const [isPosting, setPosting] = React.useState(false);

  let status = null;

  const fnWrapper = (fn) => () => {
    setPosting(true);

    fn(item.user.id);
  };

  if (item.is_approved === null) {
    status = (
      <small className="pull-right">
        <Permission name={[EPermissions.NETWORK_EXCHANGES_REVIEW, EPermissions.NETWORK_FUNCTION_MEMBERSHIPS_EXCHANGES_REVIEW]} deniedComponent={<Trans i18nKey="flexchange:replacement_status_awaiting" />}>
          <div className="Replacement__Actions">
            <Button
              type="primary"
              size="small"
              onClick={fnWrapper(onApprove)}
              isLoading={isPosting}
              icon="sync_alt"
            />
            <Button
              type="warning"
              size="small"
              onClick={fnWrapper(onReject)}
              isLoading={isPosting}
              icon="close"
            />
          </div>
        </Permission>
      </small>
    );
  } else if (item.is_approved) {
    status = <small className="pull-right"><Trans i18nKey="flexchange:replacement_status_approved" /></small>;
  } else if (!item.is_approved) {
    status = <small className="pull-right"><Trans i18nKey="flexchange:replacement_status_rejected" /></small>;
  }

  return (
    <div className="Replacement">
      <Profile
        user={item.user}
        enablePopover={item.user && item.user.id !== loggedUser.id}
      />
      {status}
    </div>
  );
};

ReplacementComponent.propTypes = {
  item: PropTypes.object.isRequired,
  loggedUser: PropTypes.object.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default ReplacementComponent;
