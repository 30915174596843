import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import PureList from '../../../../common/components/list';
import Replacement from '../replacement';

const ExchangeReplacements = ({
  exchange, replacements, loggedUser, organisation, onApprove, onReject,
}) => {
  if (!replacements || !replacements.length) return null;

  return (
    <PureList
      containerClassName="Exchange__Replacements"
      items={replacements}
      renderRow={Replacement}
      header={exchange.shift_id && organisation.integration && organisation.integration.process_exchange_approvals && (
        <div className="Exchange__Replacements__ApprovalMessage">
          <Trans i18nKey="flexchange:exchange_replacements_header" />
        </div>
      )}
      rowProps={{
        loggedUser,
        onApprove,
        onReject,
      }}
    />
  );
};

ExchangeReplacements.propTypes = {
  exchange: PropTypes.object.isRequired,
  replacements: PropTypes.array.isRequired,
  loggedUser: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default ExchangeReplacements;
