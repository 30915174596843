import memoize from '../../../common/utils/memoize';
import * as reducer from '../reducers/exchanges';

const getItem = (state, id) => state.flexchange.exchanges.items[id];
const getActivities = (state, id) => state.flexchange.activities.exchanges[id];
const getIdProp = (state, id) => id;

export const item = memoize.createItemSelector(
  [getIdProp, getItem, getActivities],
  (state, id) => reducer.findById(state, id),
);
