import * as React from 'react';
import moment from 'moment';
import Day from './day';

const Calendar = ({ week, ...props }) => (
  <div className="Calendar">
    <div className="Calendar__content">
      {week.days.map((day) => (
        <Day {...props} key={moment(day.date).format()} day={day} />
      ))}
    </div>
  </div>
);

export default Calendar;
