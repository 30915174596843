import moment from 'moment';
import Api from '../../../common/services/api';
import { ActivityTypes } from '../utils/activity';
import * as exchangeUtil from '../utils/exchange';
import createId from '../../../common/utils/create-temp-id';

export const FLEXCHANGE_APPROVE_EXCHANGE = 'flexchange/APPROVE_EXCHANGE';

const createApproveActivity = (exchange, loggedUser, userId) => ({
  type: 'activity',
  data: {
    id: createId(), // TODO: replace with actual id
    activity_type: ActivityTypes.EXCHANGE_APPROVED,
    date: moment().toISOString(),
    meta_data: { approved_user_id: userId },
    source_id: exchange.id,
    user: loggedUser,
  },
});

export default (exchangeId, userId) => async (dispatch, getState) => {
  const { network: { selected }, users, loggedUser } = getState();
  const payload = { action: 'approve', user_id: userId };

  const { data } = await Api.patch(`/v2/networks/${selected}/exchanges/${exchangeId}`, payload);

  return dispatch({
    type: FLEXCHANGE_APPROVE_EXCHANGE,
    exchangeId,
    exchange: exchangeUtil.transfromExchange(data),
    activity: createApproveActivity(data, users.items[loggedUser.user.id], userId),
  });
};
