import Api from '../../../common/services/api';

export const FLEXCHANGE_DELETE_EXCHANGE = 'flexchange/DELETE_EXCHANGE';

export default (exchangeId) => async (dispatch, getState) => {
  const { network: { selected } } = getState();
  await Api.delete(`/v2/networks/${selected}/exchanges/${exchangeId}`);

  dispatch({
    type: FLEXCHANGE_DELETE_EXCHANGE,
    exchangeId,
  });
};
