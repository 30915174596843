import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';

import { TextareaInput } from '@common/components/form/inputs/textarea';
import { Button } from '../../../../common/components/button';

class ExchangePostComment extends Component {
  static propTypes = {
    loggedUser: PropTypes.object.isRequired,
    onPostComment: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { isPosting: false, value: '' };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(e) {
    e.preventDefault();

    this.setState({ isPosting: true });
    await this.props.onPostComment(this.state.value);
    this.setState({ isPosting: false, value: '' });
  }

  render() {
    const { value } = this.state;
    const { t } = this.props;

    return (
      <form onSubmit={this.handleSubmit} className="Exchange__PostComment Form">
        <TextareaInput
          value={value}
          placeholder={t('flexchange:exchange_post_comment_placeholder')}
          className="Form__control"
          onChange={(newValue) => this.setState({ value: newValue })}
          onEnter={() => this.handleSubmit({ preventDefault: () => false })}
        />

        <Button
          type="primary"
          buttonType="submit"
          disabled={!value}
          isLoading={this.state.isPosting}
        >
          <Trans i18nKey="flexchange:exchange_post_comment_post" />
        </Button>
      </form>
    );
  }
}

export default withTranslation()(ExchangePostComment);
