import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from 'react-i18next';
import Icon from '../../../../common/components/icon';
import Permission from '../../../../common/components/permission';
import EmployeeStatus from './status.employee';
import Replacements from './replacements';
import { EExchangeStatus } from '../../utils/exchange';

const StatusFooter = ({ exchange, onAccept, onDecline }) => {
  switch (exchange.status) {
    case EExchangeStatus.OPEN:
      return (
        <div
          className="Exchange__Status Exchange__Status--state-accepted Exchange__Status--revert"
          onClick={onAccept}
        >
          <Icon type="check" color="green" />
          <Trans i18nKey="flexchange:exchange_status_admin_accept_self" components={[<b>a</b>]} />
        </div>
      );
    case EExchangeStatus.ACCEPTED:
      return (
        <div
          className="Exchange__Status Exchange__Status--state-declined Exchange__Status--revert"
          onClick={onDecline}
        >
          <Icon type="close" color="red" />
          <Trans i18nKey="flexchange:exchange_status_admin_accepted_revert" components={[<b>a</b>]} />
        </div>
      );
    case EExchangeStatus.DECLINED:
      return (
        <div
          className="Exchange__Status Exchange__Status--state-accepted Exchange__Status--revert"
          onClick={onAccept}
        >
          <Icon type="check" color="green" />
          <Trans i18nKey="flexchange:exchange_status_admin_declined_revert" components={[<b>a</b>]} />
        </div>
      );
    default: return null;
  }
};

StatusFooter.propTypes = {
  exchange: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
};

const ExchangeStatusAdmin = ({
  exchange, loggedUser, organisation, onAccept, onDecline, onApprove, onReject,
}) => {
  switch (exchange.status) {
    case EExchangeStatus.OPEN:
    case EExchangeStatus.ACCEPTED:
    case EExchangeStatus.DECLINED:
    case EExchangeStatus.REJECTED:
      return (
        <>
          <Replacements
            exchange={exchange}
            replacements={exchange.replacements}
            {...{ loggedUser, organisation, onApprove, onReject }}
          />

          <Permission test={exchange.user.id !== loggedUser.id}>
            <StatusFooter {...{ exchange, onAccept, onDecline }} />
          </Permission>
        </>
      );
    default: return (
      <EmployeeStatus
        exchange={exchange}
        loggedUser={loggedUser}
        onAccept={onAccept}
        onDecline={onDecline}
      />
    );
  }
};

ExchangeStatusAdmin.propTypes = {
  exchange: PropTypes.object.isRequired,
  loggedUser: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default ExchangeStatusAdmin;
