import permissionsSwitch from '../../../../common/utils/permission-import';
import StatusEmployee from './status.employee';
import StatusAdmin from './status.admin';
import { EPermissions } from '../../../../common/definitions';

export default permissionsSwitch(
  [EPermissions.NETWORK_FUNCTION_MEMBERSHIPS_EXCHANGES_REVIEW, EPermissions.NETWORK_EXCHANGES_REVIEW],
  {
    employee: StatusEmployee,
    admin: StatusAdmin,
  },
);
