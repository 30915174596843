import React from 'react';
import PaginatedList from '../../../../common/components/list/paginated';
import Spinner from '../../../../common/components/spinner';
import { Types } from '../../utils/activity';
import Comment from './comment';
import Activity from './activity';

const ExchangeActivityFeed = ({ loggedUser, activities }) => (
  <PaginatedList
    contianerClassName="Exchange__ActivityList"
    items={activities}
    initialListSize={3}
    renderRow={({ item }) => {
      switch (item.type) {
        case Types.COMMENT:
          return <Comment comment={item} loggedUser={loggedUser} />;
        case Types.ACTIVITY:
          return <Activity activity={item} loggedUser={loggedUser} />;
        default:
          return null;
      }
    }}
    placeholder={(
      <Spinner centered size="large" />
    )}
  />
);

export default ExchangeActivityFeed;
