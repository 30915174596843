import React from 'react';
import * as R from 'ramda';
import { withTranslation } from 'react-i18next';
import Icon from '../../../../common/components/icon';
import { EExchangeStatus, isOpen } from '../../utils/exchange';
import Base from './index.base';

class ExchangeItemAdmin extends Base {
  getStatus() {
    const { item, t } = this.props;
    const openResponses = R.filter(isOpen, item.responses);

    switch (item.status) {
      case EExchangeStatus.APPROVED:
        return {
          icon: <Icon type="check" key="check" />,
          text: t('flexchange:exchange_item_admin_status_approved'),
          className: 'ExchangeItem--state-approved',
        };
      case EExchangeStatus.EXPIRED:
        return {
          icon: null,
          text: t('flexchange:exchange_item_admin_status_expired'),
          className: 'ExchangeItem--state-expired',
        };
      default:
        if (openResponses.length > 0) {
          return {
            icon: <Icon type="access_time" key="alarm" />,
            text: t('flexchange:exchange_item_admin_status_awaiting'),
            className: null,
          };
        }

        return {
          icon: null,
          text: t('flexchange:exchange_item_admin_status_open'),
          className: 'ExchangeItem--state-open',
        };
    }
  }
}

export default withTranslation()(ExchangeItemAdmin);
