import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useHistory } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { Moment } from 'moment';

import { useAppSelector } from '@common/hooks';
import { EPermissions } from '@common/definitions';
import { Exchange as TExchange } from '@modules/flexchange/types/objects';

import { Select } from '@common/components/form/inputs/select';
import Container from '@common/components/container';
import Overview from '@common/components/overview';
import { Button } from '@common/components/button';
import Permission from '@common/components/permission';
import ButtonGroup from '@common/components/button-group';
import ToolBar from '@common/components/tool-bar';
import WeekSelector from '@common/components/week-selector';
import Dropdown from '@common/components/dropdown';
import { TableDropdown } from '@common/components/table';
import Icon from '@common/components/icon';

import Calendar from '../../components/calendar';
import Exchange from '../../components/exchange';
import AddExchangeForm from '../../forms/add-exchange/create';

import weekSelector from '../../selectors/week';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import * as networkSelector from '../../../network/selectors/network';
import * as userSelector from '../../../core/selectors/logged-user';
import { pageWrapper, EEventNames } from '../../../../../client/analytics';

import fetchExchanges from '../../actions/fetch-exchanges';
import filterExchanges from '../../actions/filter-exchanges';
import goToPreviousWeek from '../../actions/go-previous-week';
import goToNextWeek from '../../actions/go-next-week';
import goToToday from '../../actions/go-today';

import '../../styles.scss';

const Flexchange = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const loggedUser = useAppSelector(userSelector.selected);
  const organisation = useAppSelector(organisationSelector.selected);
  const network = useAppSelector((state) => networkSelector.selected(state));
  const functions = useAppSelector((state) => userSelector.functions(state, EPermissions.NETWORK_EXCHANGES_VIEW_ALL));
  const week = useAppSelector((state) => weekSelector(state));
  const ui = useAppSelector((state) => state.ui.flexchange);

  const [fetching, setFetching] = useState(false);
  const [date, setDate] = useState<Moment | null>(null);

  const [showModal, setShowModal] = useState({
    manual: false,
    integrated: false,
  });

  useEffect(() => {
    const load = async () => {
      setFetching(true);
      await dispatch(fetchExchanges(ui.currentWeek));
      setFetching(false);
    };
    load();
  }, [ui.currentWeek, dispatch, fetchExchanges, setFetching]);

  const handleGoToWeek = (action: 'now' | 'next' | 'previous') => {
    switch (action) {
      case 'now':
        return dispatch(goToToday());
      case 'next':
        return dispatch(goToNextWeek());
      case 'previous':
        return dispatch(goToPreviousWeek());
      default:
        return false;
    }
  };

  const schedulesIntegrated = !!organisation.integration?.external_schedule;

  return (
    <Container name="Flexchange">
      <Route
        path="/networks/:networkId/flexchange/exchanges/:exchangeId"
        render={({ match }) => (
          <Exchange
            exchangeId={match.params.exchangeId}
            organisation={organisation}
            loggedUser={loggedUser}
          />
        )}
      />

      <Container.Content>
        <Overview>
          <ToolBar>
            {/* @ts-expect-error */}
            <WeekSelector
              date={week.date}
              onGoToPreviousWeek={() => handleGoToWeek('previous')}
              onGoToToday={() => handleGoToWeek('now')}
              onGoToNextWeek={() => handleGoToWeek('next')}
              isFetching={fetching}
            />

            <Select
              value={ui.search.filter || ''}
              options={[
                { value: '', label: t('flexchange:container_filter_all_functions') },
                ...functions.map((item) => ({ value: item.id, label: item.name })),
              ]}
              onChange={({ value }: any) => dispatch(filterExchanges({ filter: value }))}
              clearable={false}
            />

            <div>
              <div className="hidden-sd hidden-md hidden-ld">
                <ButtonGroup
                  size="large"
                  active={ui.search.status || ''}
                  onChange={(status) => dispatch(filterExchanges({ status }))}
                >
                  <Button value=""><Trans i18nKey="flexchange:container_filter_all" /></Button>
                  <Button value="OPEN"><Trans i18nKey="flexchange:container_filter_open" /></Button>
                  <Permission
                    name={[
                      EPermissions.NETWORK_EXCHANGES_REVIEW,
                      EPermissions.NETWORK_FUNCTION_MEMBERSHIPS_EXCHANGES_REVIEW,
                    ]}
                  >
                    <Button value="AWAITING_APPROVAL"><Trans i18nKey="flexchange:container_filter_awaiting_approval" /></Button>
                  </Permission>
                  <Permission
                    fallback
                    name={[
                      EPermissions.NETWORK_EXCHANGES_REVIEW,
                      EPermissions.NETWORK_FUNCTION_MEMBERSHIPS_EXCHANGES_REVIEW,
                    ]}
                  >
                    <Button value="ACCEPTED">
                      <Trans i18nKey="flexchange:container_filter_accepted" />
                    </Button>
                  </Permission>
                  <Button value="APPROVED">
                    <Trans i18nKey="flexchange:container_filter_approved" />
                  </Button>
                </ButtonGroup>
              </div>
              <div className="visible-sd visible-md visible-ld">
                <Select
                  value={ui.search.status || ''}
                  options={[
                    { value: '', label: t('flexchange:container_search_status_all') },
                    { value: 'OPEN', label: t('flexchange:container_search_status_open') },
                    { value: 'APPROVED', label: t('flexchange:container_search_status_approved') },
                  ]}
                  onChange={({ value }: any) => dispatch(filterExchanges({ status: value !== '' ? value : null }))}
                  clearable={false}
                />
              </div>
            </div>

            <AddExchangeForm
              show={showModal.manual}
              schedulesIntegrated={false}
              date={date || undefined}
              onClose={() => setShowModal((current) => ({ ...current, manual: false }))}
            />

            <AddExchangeForm
              show={showModal.integrated}
              schedulesIntegrated
              onClose={() => setShowModal((current) => ({ ...current, integrated: false }))}
            />
            {(schedulesIntegrated && (
              <Dropdown
                alignRight
                toggle={(
                  <Button type="primary" iconRight="add" size="large">
                    <Trans i18nKey="flexchange:container_create_shift_button" />
                  </Button>
                )}
              >
                <TableDropdown.Item
                  onClick={() => setShowModal((current) => ({ ...current, manual: true }))}
                >
                  <div role="button">
                    <Icon type="insert_link" />
                    {t('flexchange:create_manual')}
                  </div>
                </TableDropdown.Item>
                <TableDropdown.Item
                  onClick={() => setShowModal((current) => ({ ...current, integrated: true }))}
                >
                  <div role="button">
                    <Icon type="assignment" />
                    {t('flexchange:create_from_shift')}
                  </div>
                </TableDropdown.Item>
              </Dropdown>
            )) || (
              <Button
                size="large"
                type="primary"
                iconRight="add"
                onClick={() => setShowModal((current) => ({ ...current, manual: true }))}
              >
                <Trans i18nKey="flexchange:container_create_shift_button" />
              </Button>
            )}
          </ToolBar>

          <Calendar
            organisation={organisation}
            loggedUser={loggedUser}
            week={week}
            openExchange={(exchange: TExchange) => {
              history.push(`/networks/${network.id}/flexchange/exchanges/${exchange.id}`);
            }}
            addExchangeForDate={(shiftDate: Moment) => {
              setDate(shiftDate);
              setShowModal((current) => ({ ...current, manual: true }));
            }}
          />
        </Overview>
      </Container.Content>
    </Container>
  );
};

export default pageWrapper(EEventNames.VISITED_FLEXCHANGE_PAGE)(Flexchange);
