import React from 'react';
import { withTranslation } from 'react-i18next';
import Icon from '../../../../common/components/icon';
import { EExchangeStatus } from '../../utils/exchange';
import Base from './index.base';

class ExchangeItemEmployee extends Base {
  getStatus() {
    const { item, t } = this.props;

    switch (item.status) {
      case EExchangeStatus.APPROVED:
        return {
          icon: <Icon type="check" />,
          text: t('flexchange:exchange_item_employee_status_approved'),
          className: 'ExchangeItem--state-approved',
        };
      case EExchangeStatus.EXPIRED:
        return {
          icon: null,
          text: t('flexchange:exchange_item_employee_status_expired'),
          className: 'ExchangeItem--state-expired',
        };
      case EExchangeStatus.DECLINED:
      case EExchangeStatus.REJECTED:
        return {
          icon: <Icon type="clear" />,
          text: t('flexchange:exchange_item_employee_status_rejected'),
          className: 'ExchangeItem--state-rejected',
        };
      case EExchangeStatus.ACCEPTED:
        return {
          icon: <Icon type="access_time" />,
          text: t('flexchange:exchange_item_employee_status_awaiting'),
          className: null,
        };
      default:
        return {
          icon: null,
          text: t('flexchange:exchange_item_employee_status_open'),
          className: 'ExchangeItem--state-open',
        };
    }
  }
}

export default withTranslation()(ExchangeItemEmployee);
