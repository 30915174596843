import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ProfileImage from '@common/components/profile-image';

const ActivityFeedComment = ({ loggedUser, comment }) => {
  const { t } = useTranslation();
  return (
    <div className="ActivityFeed__Activity">
      <div className="ActivityFeed__Activity__image">
        <ProfileImage
          user={comment.user}
          enablePopover={comment.user && comment.user.id !== loggedUser.id}
        />
      </div>
      <div className="ActivityFeed__Activity__content">
        <div><b>{comment.user ? comment.user.full_name : `"${t('flexchange:activity_feed_comment_profile_fallback')}"`}</b></div>
        <div><span className="ActivityFeed__Comment">{comment.text}</span></div>
        <div><small>{moment(comment.date).calendar()}</small></div>
      </div>
    </div>
  );
};

export default ActivityFeedComment;
