import * as React from 'react';
import { connect } from 'react-redux';
import * as userSelector from '../../modules/core/selectors/logged-user';

// type Props = {
//   permissions: Array<string>,
// }

const mapStateToProps = (state) => ({
  permissions: userSelector.permissions(state),
});

export default (requiredPermissions, components) => {
  const PermissionImport = ({ permissions, ...props }) => {
    const isAdmin = requiredPermissions && requiredPermissions.some((permission) => permissions.includes(permission));
    const Component = components[isAdmin ? 'admin' : 'employee'];

    if (!Component) return null;

    return <Component {...props} />;
  };

  return connect(mapStateToProps)(PermissionImport);
};
